/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/material.theme.css";

@font-face {
  font-family: 'manrope';
  src: url('../src/fonts/manrope.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../src/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  overflow-x: hidden;
  letter-spacing: 1.1px;
  font-family: 'Source Sans Pro';
}

.backdropBackground {
  background-color: #000000;
  opacity: 80% !important;
}


.header-text {
  font-family: 'manrope';
  font-size: 36px;
  line-height: 45px;
 }

.subheader-text {
  font-size: 18px;
  line-height: 27px;
}

.highlighted-text {
  font-family: 'manrope';
  }

 .headerText {
  font-family: 'manrope';
}

.headText {
  font-family: 'manrope';
}

.mat-mdc-form-field .mdc-text-field__input {
  font-family: 'Source Sans Pro' !important;
}


.login-page {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #C3966E !important;
    background-color: #C3966E !important;
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #fff !important;
  }
  .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
    opacity: 0 !important;
    background-color: transparent !important;
  }
}
